import React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface ViewsIconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}
const ViewsIcon: React.FC<ViewsIconProps> = ({
  width = 17,
  height = 17,
  fill = '#B2B2B2',
  className
}) => {
  return _jsxs("svg", {
    width: width,
    height: height,
    viewBox: "0 0 17 17",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    className: className,
    children: [_jsx("path", {
      d: "M7.25 8.62496C7.25 7.79653 7.92157 7.12496 8.75 7.12496C9.57842 7.12496 10.25 7.79653 10.25 8.62496C10.25 9.45339 9.57842 10.125 8.75 10.125C7.92157 10.125 7.25 9.45339 7.25 8.62496Z",
      fill: fill
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2.08333 8.62496C2.08333 9.71788 2.36664 10.0859 2.93326 10.8221C4.06464 12.2919 5.96207 13.9583 8.75 13.9583C11.5379 13.9583 13.4354 12.2919 14.5667 10.8221C15.1334 10.0859 15.4167 9.71788 15.4167 8.62496C15.4167 7.53204 15.1334 7.16398 14.5667 6.42785C13.4354 4.958 11.5379 3.29163 8.75 3.29163C5.96207 3.29163 4.06464 4.958 2.93326 6.42785C2.36664 7.16398 2.08333 7.53204 2.08333 8.62496ZM8.75 6.12496C7.36928 6.12496 6.25 7.24425 6.25 8.62496C6.25 10.0057 7.36928 11.125 8.75 11.125C10.1307 11.125 11.25 10.0057 11.25 8.62496C11.25 7.24425 10.1307 6.12496 8.75 6.12496Z",
      fill: fill
    })]
  });
};
export default ViewsIcon;