import React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface FollowersIconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}
const FollowersIcon: React.FC<FollowersIconProps> = ({
  width = 17,
  height = 17,
  fill = '#B2B2B2',
  className
}) => {
  return _jsxs("svg", {
    width: width,
    height: height,
    viewBox: "0 0 17 17",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    className: className,
    children: [_jsx("path", {
      d: "M11.0833 5.62496C11.0833 6.91362 10.0387 7.95829 8.75001 7.95829C7.46134 7.95829 6.41667 6.91362 6.41667 5.62496C6.41667 4.33629 7.46134 3.29163 8.75001 3.29163C10.0387 3.29163 11.0833 4.33629 11.0833 5.62496Z",
      fill: fill
    }), _jsx("path", {
      d: "M12.75 11.625C12.75 12.9136 10.9591 13.9583 8.75 13.9583C6.54086 13.9583 4.75 12.9136 4.75 11.625C4.75 10.3363 6.54086 9.29163 8.75 9.29163C10.9591 9.29163 12.75 10.3363 12.75 11.625Z",
      fill: fill
    }), _jsx("path", {
      d: "M5.49803 3.95837C5.61634 3.95837 5.73183 3.96998 5.84336 3.99208C5.57166 4.47468 5.41666 5.03176 5.41666 5.62504C5.41666 6.20388 5.5642 6.74825 5.82372 7.22261C5.71829 7.24226 5.60941 7.25255 5.49803 7.25255C4.55508 7.25255 3.79068 6.51512 3.79068 5.60546C3.79068 4.6958 4.55508 3.95837 5.49803 3.95837Z",
      fill: fill
    }), _jsx("path", {
      d: "M4.38155 13.2824C4.00294 12.8298 3.74999 12.2744 3.74999 11.625C3.74999 10.9955 3.98771 10.4544 4.34718 10.0095C3.07739 10.108 2.08333 10.8025 2.08333 11.6446C2.08333 12.4946 3.09486 13.1943 4.38155 13.2824Z",
      fill: fill
    }), _jsx("path", {
      d: "M12.0833 5.62504C12.0833 6.20388 11.9357 6.74825 11.6762 7.22262C11.7816 7.24226 11.8905 7.25255 12.0019 7.25255C12.9448 7.25255 13.7093 6.51512 13.7093 5.60546C13.7093 4.6958 12.9448 3.95837 12.0019 3.95837C11.8836 3.95837 11.7681 3.96998 11.6566 3.99208C11.9283 4.47468 12.0833 5.03176 12.0833 5.62504Z",
      fill: fill
    }), _jsx("path", {
      d: "M13.1184 13.2824C14.4051 13.1943 15.4166 12.4946 15.4166 11.6446C15.4166 10.8025 14.4225 10.108 13.1528 10.0095C13.5122 10.4544 13.7499 10.9955 13.7499 11.625C13.7499 12.2744 13.497 12.8298 13.1184 13.2824Z",
      fill: fill
    })]
  });
};
export default FollowersIcon;